<script lang="ts">
  import * as d3 from "d3";
  import { geoRobinson } from "d3-geo-projection";
  import { onMount } from "svelte";
  import Legend from "./lib/color-legend.js";
  import Axios from "axios";
  import { residencesFiltered, postcodesFiltered, parishesFiltered, userLocations, selectedProperty, selectedResidence } from "./filterdata.js";
  import { residencesSelected, postcodesSelected, parishesSelected } from './selectiondata.js'
  import { filterList, locationsToSearch } from "./filter.js";
  import { Button, ButtonDropdown, ButtonGroup, DropdownItem, DropdownMenu, DropdownToggle, Input, Dropdown, Modal, ModalBody, ModalFooter, ModalHeader } from "sveltestrap";
  import { availableAttributes, getName } from "./structure.js";

  const width = 975;
  const height = 770;

  let showPostcode = true;
  let showParish = false;
  let showDots = false;
  let addLocation = false;
  let locations = $userLocations;
  let unnamedPointsMade = 0; 
  let locationWithoutName = null;
  let searchingForLoc = false;
  let locationName = ((10).toString(36).toUpperCase());
  
  const maxSearchListLen = 5;


  let svg;
  const zoom = d3.zoom().scaleExtent([1, 15]).on("zoom", zoomed);
  const projection = geoRobinson();
  let geoGenerator = d3.geoPath().projection(projection);
  let areaColorScaleFilter: d3.ScaleSequential<string, never>;
  let areaColorScaleSelect: d3.ScaleSequential<string, never>;

  function addLocationPoint(latLong) {
    locationWithoutName = latLong;
  }

  function addNamedLocation() {
    let locationPoint = {
      coor: locationWithoutName,
      name: locationName, 
      prettyName: "Distance to " + locationName,
      isLoc: true
    }
    if (locationName == ((unnamedPointsMade+10).toString(36).toUpperCase())) {
      unnamedPointsMade++;
    }
    locations.push(locationPoint);
    locations = locations;
    userLocations.set(locations);
    locationWithoutName = null;
    locationName = ((unnamedPointsMade+10).toString(36).toUpperCase())
  }

  $: {
    areaColorScaleFilter = d3
      .scaleSequential(d3.interpolateGreys)
      .domain(showPostcode?[
        Math.min(...Object.values($postcodesSelected)),
        Math.max(...Object.values($postcodesSelected)),
      ]:[
        Math.min(...Object.values($parishesSelected)),
        Math.max(...Object.values($parishesSelected)),
      ]);
  }
  $: {
    $selectedProperty;
    areaColorScaleSelect = d3
      .scaleSequential(d3.interpolateGreens)
      .domain(showPostcode?[
        Math.min(...Object.values($postcodesSelected)),
        Math.max(...Object.values($postcodesSelected)),
      ]:[
        Math.min(...Object.values($parishesSelected)),
        Math.max(...Object.values($parishesSelected)),
      ]);
    resetLegend(areaColorScaleSelect);
  }

  let postcodeFeatures = [];
  let backgroundFeatures = [];
  let parishFeatures = [];
  let roadFeatures = [];

  Axios.get("geojson/postcodes.geojson").then((response) => {
    const postcodeGeojson = response.data;
    postcodeFeatures = postcodeGeojson.features;

    projection.fitExtent(
      [
        [0, 0],
        [width, height],
      ],
      postcodeGeojson
    );
  });

  Axios.get("geojson/parishes.geojson").then((response) => {
    parishFeatures = response.data.features;
  });

  Axios.get("geojson/dk.geojson").then((response) => {
    backgroundFeatures = response.data.features;
  });

  Axios.get("geojson/roads.geojson").then((response) => {
    roadFeatures = response.data.features;
  });

  function resetLegend(areaColorScale) {
    if (svg == undefined) return;
    svg.select("#colorscale").selectChildren("svg").remove();
    svg
      .select("#colorscale")
      .append(() =>
        Legend(areaColorScale, { title: getName($selectedProperty), width: 260 })
      );
  }

  function zoomed(event) {
    const { transform } = event;
    svg.select("g").attr("transform", transform);
  }

  function postcodeClicked(e, postcode){
    if (addLocation) return;
    postcode = Number(postcode);
    let filterIndex = $filterList.findIndex((element)=>element.name=="Postcode");
    let postcodeFilter;
    if (filterIndex != -1){
      postcodeFilter = $filterList[filterIndex];
      if (postcodeFilter.in.indexOf(postcode)==-1){
        postcodeFilter.in.push(postcode);
      }else{
        postcodeFilter.in.splice(postcodeFilter.in.indexOf(postcode),1);
        if (postcodeFilter.in.length == 0) {
            filterList.update((filter=>{
                filter.splice(filter.findIndex((element)=>element.name=="Postcode"),1);
                return filter;
            }))
            return;
        }
      }
      filterList.update((filter=>{
        filter.splice(filter.findIndex((element)=>element.name=="Postcode"),1);
        filter.push(postcodeFilter);
        return filter;
      }))
    }else{
      postcodeFilter = {name:"Postcode", in:[postcode]};
      filterList.update((filter)=>{
        filter.push(postcodeFilter);
        return filter;
      })
    }
  }

  let searchValue = "";

  function parishClicked(e, parish){
    if (addLocation) return;
    parish = Number(parish);
    let filterIndex = $filterList.findIndex((element)=>element.name=="Parish");
    let parishFilter;
    if (filterIndex != -1){
      parishFilter = $filterList[filterIndex];
      if (parishFilter.in.indexOf(parish)==-1){
        parishFilter.in.push(parish);
      }else{
        parishFilter.in.splice(parishFilter.in.indexOf(parish),1);
        if (parishFilter.in.length == 0) {
            filterList.update((filter=>{
                filter.splice(filter.findIndex((element)=>element.name=="Parish"),1);
                return filter;
            }))
            return;
        }
      }
      filterList.update((filter=>{
        filter.splice(filter.findIndex((element)=>element.name=="Parish"),1);
        filter.push(parishFilter);
        return filter;
      }))
    }else{
      parishFilter = {name:"Parish", in:[parish]};
      filterList.update((filter)=>{
        filter.push(parishFilter);
        return filter;
      })
    }
  }
  

  onMount(async function (): Promise<void> {
    svg = d3.select("#geoMapSvg");
    svg.call(zoom);
    resetLegend(areaColorScaleSelect);
    svg.select("g").on("click", function(e) {
      if (addLocation) {
        addLocationPoint(projection.invert(d3.pointer(e)));
        addLocation = false;
      }
    })
  });
</script>

<main style="position:relative;">
  <div>
    <Modal isOpen={locationWithoutName != null}>
      <ModalHeader>Name location</ModalHeader>
      <ModalBody>
        <Input id="nameText" bind:value={locationName} />
      </ModalBody>
      <ModalFooter>
        <Button color="primary" on:click={addNamedLocation}>Save</Button>
      </ModalFooter>
    </Modal>
    <svg viewBox="0 0 {width} {height}" id="geoMapSvg">
      <g>
        <g id="background">
          {#each backgroundFeatures as feature}
            <path d={geoGenerator(feature)} class="background" />
          {/each}
        </g>
        {#if showParish}
        <g style="visibility: {showParish ? 'visible' : 'hidden'};">
          {#each parishFeatures as feature (feature.properties.parishcode)}
            <!-- svelte-ignore a11y-click-events-have-key-events -->
            <path
              d={geoGenerator(feature)}
              class="parish"
              id={feature.properties.parishcode}
              name={feature.properties.name}
              style="fill: {$parishesSelected[feature.properties.parishcode]?areaColorScaleSelect(
                $parishesSelected[feature.properties.parishcode]
              ): ($parishesFiltered[feature.properties.parishcode]?areaColorScaleFilter(
                $parishesFiltered[feature.properties.parishcode]
              ):"#ddd")}"
              on:click={(e) => {
                parishClicked(e,feature.properties.parishcode);
                }}
            />
          {/each}
        </g>
        {/if}
        {#if showPostcode}
        <g style="visibility: {showPostcode ? 'visible' : 'hidden'};">
          {#each postcodeFeatures as feature (feature.properties.postcode)}
            <!-- svelte-ignore a11y-click-events-have-key-events -->
            <path
              d={geoGenerator(feature)}
              class="postcode"
              id={feature.properties.postcode}
              name={feature.properties.name}
              style="fill: {$postcodesSelected[feature.properties.postcode] ? areaColorScaleSelect(
                $postcodesSelected[feature.properties.postcode]
              ) : ($postcodesFiltered[feature.properties.postcode]?areaColorScaleFilter(
                $postcodesFiltered[feature.properties.postcode]
              ):"#ddd")}"
              on:click={(e) => {
                postcodeClicked(e,feature.properties.postcode);
                }}
            />
          {/each}
        </g>
        {/if}
        <g>
          {#each roadFeatures as feature}
            <!-- svelte-ignore a11y-click-events-have-key-events -->
            <path
              d={geoGenerator(feature)}
              class="road"
              id={feature.properties.vejkode}
              style="fill: transparent"
            />
          {/each}
        </g>
        {#if showDots}
        <g>
          {#each $residencesFiltered as marker (marker.ID)}
            <!-- svelte-ignore a11y-click-events-have-key-events -->
            <circle
              cx={projection([marker.Long,marker.Lat])[0]}
              cy={projection([marker.Long,marker.Lat])[1]}
              style="cursor: pointer; fill: {areaColorScaleFilter(marker[$selectedProperty])};"
              r="0.2"
              on:click={()=>{selectedResidence.set(marker)}}
            />
          {/each}
          {#each $residencesSelected as marker (marker.ID)}
            <!-- svelte-ignore a11y-click-events-have-key-events -->
            <circle
              cx={projection([marker.Long,marker.Lat])[0]}
              cy={projection([marker.Long,marker.Lat])[1]}
              style="cursor: pointer; fill: {areaColorScaleSelect(marker[$selectedProperty])};"
              r="0.2"
              on:click={()=>{selectedResidence.set(marker)}}
            />
          {/each}
          {#if $selectedResidence.ID}
            <circle
              cx={projection([$selectedResidence.Long,$selectedResidence.Lat])[0]}
              cy={projection([$selectedResidence.Long,$selectedResidence.Lat])[1]}
              style="cursor: pointer; stroke: white; fill: {areaColorScaleSelect($selectedResidence[$selectedProperty])};"
              r="1"
            />
          {/if}
        </g>
        {/if}
        <g>
          {#each locations as marker (marker.name)}
          <circle
          cx={projection(marker.coor)[0]}
          cy={projection(marker.coor)[1]}
          style="fill: white"
          r="3"
          />
          <circle
          cx={projection(marker.coor)[0]}
          cy={projection(marker.coor)[1]}
          style="fill: purple"
          r="2"
          />
          {/each}
        </g>
      </g>
      <g id="colorscale" transform="translate(610,20)" />
    </svg>
  </div>
  <div style="position: absolute; bottom: 0.2rem; left: 1.5rem;">
  <ButtonGroup size="sm">
  <Button on:click={()=>{showPostcode = true; showParish = false; showDots=false; addLocation=false;}} active={showPostcode}>Postcodes</Button>
  <Button on:click={()=>{showPostcode = false; showParish = true; showDots=false; addLocation=false;}} active={showParish}>Parishes</Button>
  <Button on:click={()=>{showPostcode = false; showParish = false; showDots=true; addLocation=false;}} active={showDots}>Dots</Button>
  </ButtonGroup>
  <Button size="sm" on:click={()=>{addLocation=true;}} active={addLocation}>Add location</Button>
  {#if addLocation}
    <Dropdown isOpen={searchingForLoc} toggle={() => (searchingForLoc = !searchingForLoc)}>
    <DropdownToggle tag="div" class="d-inline-block">
      <Input 
      bind:value={searchValue}
      />
    </DropdownToggle>
    <DropdownMenu>
      {#each locationsToSearch.filter((item) => item["Address"].toLowerCase().includes(searchValue.toLowerCase())).slice(0,maxSearchListLen) as addr}
        <DropdownItem on:click={()=>{
          searchValue = "";
          addLocationPoint([addr["Long"], addr["Lat"]]);
          addLocation = false;
          }}>{addr["Address"]}</DropdownItem>
      {/each}
    </DropdownMenu>
    </Dropdown>
  {/if}
  </div>
  <ButtonDropdown size="sm" style="position: absolute; top: 0.5rem; right: 1.5rem;">
    <DropdownToggle caret>✏️</DropdownToggle>
    <DropdownMenu>
      {#each availableAttributes as attribute}
        {#if attribute.plottable}
          <DropdownItem active={attribute.colName == $selectedProperty} on:click={()=>{selectedProperty.set(attribute.colName)}}>{attribute.prettyName}</DropdownItem>
        {/if}
      {/each}
    </DropdownMenu>
  </ButtonDropdown>
</main>

<style>
  .road {
    stroke:rgba(0, 0, 0, 0.5);
    stroke-width: 0.1;
    pointer-events: none;
  }
  .postcode {
    stroke: black;
    stroke-width: 0.25;
    cursor: pointer;
  }
  .parish {
    stroke:black;
    stroke-width: 0.25;
    cursor: pointer;
  }
  .postcode:hover {
    stroke:white !important;
    stroke-width: 1 !important;
  }
  .parish:hover {
    stroke:white !important;
    stroke-width: 1 !important;
  }
  #geoMapSvg #background {
    fill: #ddd;
  }
  #geoMapSvg {
    background-color: aliceblue;
  }
</style>
