<script lang="ts">
    import * as d3 from "d3";
    import { onMount } from "svelte";
    import { residencesFiltered } from "./filterdata.js";
    import { residencesSelected } from "./selectiondata.js";

    const width = 975;
    const height = 610;
    let gy, gx;
    let x_, y_;
    let binwidth, binsize;
    let bins = 10;
    let sizes = Array(bins).fill(0);
    let selectedSizes = Array(bins).fill(0);
    let margins = {"r": 30, "l": 90, "t": 30, "b": 30};
    let x_min, x_max, y_max;

    export let x_axis_prop = "Area";
    let svg;

    function addXAxis(svg, x) {
        if (svg == undefined) return;
        gx = svg.append("g")
            .attr("transform", "translate(0," + (height - margins.t) + ")")
            .call(d3.axisBottom(x));
    }

    function addYAxis(svg, y) {
        if (svg == undefined) return;
        gy = svg.append("g")
            .attr("transform", "translate(90,0)")
            .call(d3.axisLeft(y));
    }

    $: x_max = $residencesFiltered.reduce(getXAxisPropMaxIfDefined, 0);
    $: x_min = $residencesFiltered.reduce(getXAxisPropMinIfDefined, 0);
    $: y_max = 1//Math.max(...sizes);
    $: binsize = Math.floor((x_max - x_min) / bins); //Width in amount
    $: binwidth = x_(x_min + binsize)-x_(x_min) //(width-(margins.l+margins.r))/bins;  //Width in SVG pixels (x_(x_max)-x_(x_min)) / (bins-1);

    $: x_ = d3
        .scaleLinear()
        .domain([x_min, x_max])
        .range([margins.l, width - margins.r]);

    $: y_ = d3
        .scaleLinear()
        .domain([0, y_max])
        .range([height - margins.b, margins.t]);

    function getXAxisPropMaxIfDefined(maximum, item: any) {
        if (item != undefined) {
            return Math.max(maximum, item[x_axis_prop]);
        }
    }
    function getXAxisPropMinIfDefined(maximum, item: any) {
        if (item != undefined) {
            return Math.min(maximum, item[x_axis_prop]);
        }
    }

    function getSizes(data) {
        let s = Array(bins).fill(0);
        let cnt = 0;
        for (const res of data) {
            let val = res[x_axis_prop];
            if (isNaN(val))
                continue
            cnt ++;
            for (let i = 0; i < bins; i++) {
                if ((binsize * i < val && val <= binsize * (i + 1)) || (val == 0 && i==0)) {
                    s[i] += 1;
                }
            }
        }
        return [s, cnt];
    }
    function normalize(s, cnt) {
        for (let i = 0; i < bins; i++) {
            s[i] /= cnt;
        }
        return s;
    }

    $: {
        let [s, cnt] = getSizes($residencesFiltered);
        sizes = normalize(s, cnt);
        if ($residencesSelected.length == $residencesFiltered.length)
            selectedSizes = Array(bins).fill(0);
        else {
            selectedSizes = normalize(getSizes($residencesSelected)[0], cnt);
        }

        if (x_max != 0 && gx != undefined) {
            gx.transition()
                .duration(750)
                .call(d3.axisBottom(x_));
        }
    }

    onMount(async function (): Promise<void> {
        svg = d3.select("#Histogram-" + x_axis_prop);
        addYAxis(svg, y_);
        addXAxis(svg, x_);
    });
</script>

<main>
    <svg viewBox="0 0 {width} {height}" id="Histogram-{x_axis_prop}">
        <g>
            <g id="bar-{x_axis_prop}">
                {#each sizes as v, i}
                    <rect
                            width="{binwidth}"
                            x="{margins.l+i*binwidth}"
                            height="{y_(0) - y_(v)}"
                            y="{y_(v)}"
                            style="fill: grey"
                    />
                {/each}
                {#each selectedSizes as v, i}
                    <rect
                            width="{binwidth}"
                            x="{margins.l+i*binwidth}"
                            height="{y_(0) - y_(v)}"
                            y="{y_(v)}"
                            style="fill: blue"
                    />
                {/each}
            </g>
        </g>
    </svg>

</main>
