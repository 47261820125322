<script lang="ts">
    import * as d3 from "d3";
    import { onMount } from "svelte";
    import { residencesFiltered } from "./filterdata.js";
    import { drawXaxis, drawYaxis} from "./canvasAxis.js";
    import { residencesSelected } from "./selectiondata.js";
    import { propertyTypeColors } from "./structure.js";
    const zip= rows=>rows[0].map((_,c)=>rows.map(row=>row[c]))

    const width = 400;
    const height = 300;
    const margin_division = 10;
    const radius = 0.75;
    const color_deselected = "gray";
    export let x_axis_prop = "Area";
    export let y_axis_prop = "EvalPrice";
    var base;
    var chart;
    var context;

    function replaceCanvas() {
        if (base != undefined) {

            document.getElementById("ScatterPlot-" + x_axis_prop + "-" + y_axis_prop)?.firstChild?.remove(); // remove old chart, if any
            chart = base
                .append("canvas")
                .attr("id", "canvas")
                .attr("height", height)
                .attr("width", width)
                .attr("style", "width: 100%;");

            context = chart.node().getContext("2d");
        }
    }

    onMount(async function (): Promise<void> {
        base = d3.select("#ScatterPlot-" + x_axis_prop + "-" + y_axis_prop);
        replaceCanvas()
        draw($residencesFiltered, $residencesSelected);
    });

    function draw(data: Array<Object>, selectedData) {
        if (context == undefined) {
            return;
        }
        if (data == undefined) {
            return;
        }
        replaceCanvas();
        let extent_of_x = data.map((item) => item[x_axis_prop]);
        let extent_of_y = data.map((item) => item[y_axis_prop]);
        let dataPropertyTypes = data.map((item) => item["PropertyType"]);
        let xy_data = zip([extent_of_x, extent_of_y, dataPropertyTypes])

        let xy_selected_data = selectedData.map((item) => [item[x_axis_prop], item[y_axis_prop], item["PropertyType"]]);

        // Add X axis.
        var x = d3
            .scaleLinear()
            .domain(d3.extent(extent_of_x))
            .range([width / margin_division, width - width / margin_division]);

        // Add Y axis
        var y = d3
            .scaleLinear()
            .domain(d3.extent(extent_of_y))
            .range([
                height - height / margin_division,
                height / margin_division,
            ]);

        for (var i = 0; i < xy_data.length; i++) {
            context.fillStyle = (selectedData.length>0)?color_deselected:propertyTypeColors[xy_data[i][2]];
            context.beginPath();
            context.arc(
                x(xy_data[i][0]),
                y(xy_data[i][1]),
                radius,
                0,
                2 * Math.PI,
                true
            );
            context.fill();
            context.closePath();
        }
        for (var i = 0; i < xy_selected_data.length; i++) {
            context.fillStyle = propertyTypeColors[xy_selected_data[i][2]];
            context.beginPath();
            context.arc(
                x(xy_selected_data[i][0]),
                y(xy_selected_data[i][1]),
                radius,
                0,
                2 * Math.PI,
                true
            );
            context.fill();
            context.closePath();
        }
        drawXaxis(
            context,
            x,
            height - height / margin_division + 2,
            d3.extent(extent_of_x)
        );
        drawYaxis(context, y, width - 10, d3.extent(extent_of_y));
    }

    $: {
        draw($residencesFiltered, $residencesSelected);
    }
</script>

<div id="ScatterPlot-{x_axis_prop}-{y_axis_prop}" />

<style>
</style>
