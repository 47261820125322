import { createDbWorker } from "sql.js-httpvfs"

const workerUrl = "/build/lib/sqlite.worker.js";
const wasmUrl = "/build/lib/sql-wasm.wasm";

export const emptyPostcodeValues = {
  8355: 0,
  8660: 0,
  8471: 0,
  8381: 0,
  8380: 0,
  8530: 0,
  8520: 0,
  8382: 0,
  8200: 0,
  8220: 0,
  8462: 0,
  8362: 0,
  8464: 0,
  8260: 0,
  8270: 0,
  8310: 0,
  8320: 0,
  8361: 0,
  8330: 0,
  8340: 0,
  8300: 0,
  8000: 0,
  8230: 0,
  8210: 0,
  8240: 0,
  8250: 0,
  8541: 0,
  8543: 0,
  8305: 0,
};

export const emptyPostcodePairs = {
  8355: [0,0],
  8660: [0,0],
  8471: [0,0],
  8381: [0,0],
  8380: [0,0],
  8520: [0,0],
  8530: [0,0],
  8382: [0,0],
  8200: [0,0],
  8220: [0,0],
  8462: [0,0],
  8362: [0,0],
  8464: [0,0],
  8260: [0,0],
  8270: [0,0],
  8310: [0,0],
  8320: [0,0],
  8361: [0,0],
  8330: [0,0],
  8340: [0,0],
  8300: [0,0],
  8000: [0,0],
  8230: [0,0],
  8210: [0,0],
  8240: [0,0],
  8250: [0,0],
  8541: [0,0],
  8543: [0,0],
  8305: [0,0]
};

export const emptyParishValues = {8036: 0, 8065: 0, 8066: 0, 8068: 0, 8071: 0, 8072: 0, 8073: 0, 8074: 0, 8079: 0, 8080: 0, 8081: 0, 8082: 0, 8100: 0, 8101: 0, 8102: 0, 8103: 0, 8104: 0, 8105: 0, 8106: 0, 8107: 0, 8108: 0, 8109: 0, 8110: 0, 8111: 0, 8112: 0, 8113: 0, 8114: 0, 8115: 0, 8116: 0, 8117: 0, 8118: 0, 8119: 0, 8120: 0, 8121: 0, 8122: 0, 8123: 0, 8124: 0, 8125: 0, 8126: 0, 8127: 0, 8128: 0, 8129: 0, 8130: 0, 8131: 0, 8132: 0, 8246: 0, 8247: 0, 8248: 0, 8249: 0, 8250: 0, 9088: 0, 9090: 0, 9096: 0, 9097: 0, 9135: 0, 9146: 0, 9147: 0, 9158: 0, 9183:0};
export const emptyParishPairs = {8036: [0,0], 8065: [0,0], 8066: [0,0], 8068: [0,0], 8071: [0,0], 8072: [0,0], 8073: [0,0], 8074: [0,0], 8079: [0,0], 8080: [0,0], 8081: [0,0], 8082: [0,0], 8100: [0,0], 8101: [0,0],  8102: [0,0], 8103: [0,0], 8104: [0,0], 8105: [0,0], 8106: [0,0], 8107: [0,0], 8108: [0,0], 8109: [0,0], 8110: [0,0], 8111: [0,0], 8112: [0,0], 8113: [0,0], 8114: [0,0], 8115: [0,0], 8116: [0,0], 8117: [0,0], 8118: [0,0], 8119: [0,0], 8120: [0,0], 8121: [0,0], 8122: [0,0], 8123: [0,0], 8124: [0,0], 8125: [0,0], 8126: [0,0], 8127: [0,0], 8128: [0,0], 8129: [0,0], 8130: [0,0], 8131: [0,0], 8132: [0,0], 8246: [0,0], 8247: [0,0], 8248: [0,0], 8249: [0,0], 8250: [0,0], 9088: [0,0], 9090: [0,0], 9096: [0,0], 9097: [0,0], 9135: [0,0], 9146: [0,0], 9147: [0,0], 9158: [0,0], 9183: [0,0]};

export const db = new Promise(async (resolve, reject)=>{
  const worker = await createDbWorker(
    [
      {
        from: "inline",
        config: {
          serverMode: "full",
          url: "https://next.salr.cc/s/DHTfZyS3YprAJ3J/download/boliger.db",
          requestChunkSize: 4096,
        },
      },
    ],
    workerUrl,
    wasmUrl
  );
  resolve(worker.db);
});