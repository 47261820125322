<script lang="ts">
  import {
    Button,
    Card,
    CardBody,
    CardHeader,
    CardTitle,
    Fade,
    Input,
  } from "sveltestrap";
  import { availableAttributes, derivedProperties } from "./structure";
    import { userLocations } from "./filterdata";
  let isOpen = false;
  let selectedInputA = "";
  let selectedInputB = "";
  let selectedOperator = "/";
  let selectedName = "";
  let operators = ["/", "*", "+", "-"];
</script>

<main>
  <Button on:click={() => (isOpen = !isOpen)}>Advanced</Button>
  <Fade {isOpen}>
    <h2>Derived properties:</h2>
    {#each $derivedProperties as derivedProp}
      <Card>
        <CardHeader>
          <CardTitle>
            {derivedProp.prettyName}
          </CardTitle>
        </CardHeader>
        <CardBody>
          [{derivedProp.inputA}] {derivedProp.operator} [{derivedProp.inputB}]
          <Button
            color="danger"
            on:click={() => {
              derivedProperties.update((list) => {
                list.splice(list.indexOf(derivedProp), 1);
                return list;
              });
            }}>Remove</Button>
        </CardBody>
      </Card>
      <br/>
    {/each}
    <Card
      ><CardHeader>
        <CardTitle>Add new derived property</CardTitle>
      </CardHeader>
      <CardBody>
        <Input type="select" bind:value={selectedInputA}>
          {#each availableAttributes as attribute}
            <option value={attribute.colName}>{attribute.prettyName}</option>
          {/each}
          {#each $userLocations as attribute}
            <option value={attribute.name}>{attribute.prettyName}</option>
          {/each}
          {#each $derivedProperties as attribute}
            <option value={attribute.colName}>{attribute.prettyName}</option>
          {/each}
        </Input>
        <Input type="select" bind:value={selectedOperator}>
          {#each operators as operator}
            <option value={operator}>{operator}</option>
          {/each}
        </Input>
        <Input type="select" bind:value={selectedInputB}>
          {#each availableAttributes as attribute}
            <option value={attribute.colName}>{attribute.prettyName}</option>
          {/each}
          {#each $userLocations as attribute}
            <option value={attribute.name}>{attribute.prettyName}</option>
          {/each}
          {#each $derivedProperties as attribute}
            <option value={attribute.colName}>{attribute.prettyName}</option>
          {/each}
        </Input>
        <Input bind:value={selectedName} placeholder="Name..."/>
        <Button on:click={()=>{
          derivedProperties.update((properties)=>{
            properties.push({colName:selectedName.replace(/\W/g, ''), prettyName:selectedName, inputA:selectedInputA, inputB:selectedInputB, operator:selectedOperator});
            return properties;
          })
        }} disabled={(selectedInputA == "" || selectedInputB == "" || selectedName=="")}>
          Add
        </Button>
      </CardBody>
    </Card>
    <br />
  </Fade>
</main>
