<script lang="ts">
  import { Button, Card, CardBody, CardHeader, CardTitle } from "sveltestrap";
import { filterList } from "./filter.js";
import { userLocations } from "./filterdata.js";
import { getName, propertyTypeNames } from "./structure.js";
  import {PostcodeDict} from "./structure.js";
</script>
<main>
  <h2>Filters applied:</h2>
{#each $filterList as filterElement }
  <Card style="margin-bottom:4px;">
    <CardHeader>
      <CardTitle style="margin-bottom: 0px;">
        {getName(filterElement)}
        <!-- svelte-ignore a11y-click-events-have-key-events -->
        <span class="removeButton" on:click={()=>{filterList.update(list=>{
          list.splice(list.indexOf(filterElement),1);
          return list})}}>❌</span>
      </CardTitle>
    </CardHeader>
    <CardBody style="padding-top: 4px; padding-bottom: 4px; ">
      {#if filterElement.min}
        Minimum: {filterElement.min.toFixed(0)} <br>
      {/if}
      {#if filterElement.max}
        Maximum: {filterElement.max.toFixed(0)} <br>
      {/if}
      {#if filterElement.in}
        Must be in: {filterElement.name=="Postcode"?filterElement.in.map((x) => " " + PostcodeDict[x]):(filterElement.name=="PropertyType"?filterElement.in.map((x) => " " + propertyTypeNames[x]):filterElement.in)} <br>
      {/if}
    </CardBody>
  </Card>
{/each}
{#if $userLocations.length>0}
<h2>Your locations:</h2>
{/if}
{#each $userLocations as userLocation }
  <Card style="margin-bottom:4px;">
    <CardHeader>
      <CardTitle>
        {userLocation.name}
      </CardTitle>
    </CardHeader>
    <CardBody>
    <Button color="danger" on:click={()=>{userLocations.update(list=>{
        list.splice(list.indexOf(userLocation),1);
        return list})}}>Remove</Button>
    </CardBody>
  </Card>
{/each}
</main>

<style>
  .removeButton{
    float: right;
    cursor: pointer;
  }
</style>