<script lang="ts">
    import * as Plot from "@observablehq/plot";
    import { residencesFiltered } from "./filterdata.js";
    import { residencesSelected } from "./selectiondata.js";
    import {sequentialTypeColors} from "./structure.js";

    export let x_axis_prop = "Area";
    export let y_axis_prop = "EvalPrice";
    let div;

    $: {
        div?.firstChild?.remove(); // remove old chart, if any
        const plot = Plot.plot({
            y: { tickFormat: "s" },
            color: { legend: true, scheme: sequentialTypeColors },
            style: { width:"100%" },
            marks: [
                Plot.frame({ fill: "black", opacity: 0.5 }),
                Plot.rect(
                    $residencesSelected.length > 0 ? $residencesSelected : $residencesFiltered,
                    Plot.bin(
                        { fill: "count" },
                        {
                            x: x_axis_prop,
                            y: y_axis_prop,
                            fill: "currentColor",
                        }
                    )
                ),
            ],
        });
        div?.append(plot);
    }
</script>

<div bind:this={div} />

<style>
</style>