<script lang="ts">
	import Map from "./Map.svelte";
	import {
		Col,
		Container,
		Modal,
		Row,
		Spinner,
		Toast,
		ToastBody,
		ToastHeader,
	} from "sveltestrap";
	import {
    residencesFiltered,
		salesTruncated,
		selectedResidence,
		updatingHistoricalSales,
		updatingParishes,
		updatingPostcodes,
		updatingResidences,
	} from "./filterdata.js";
	import FilterManager from "./FilterManager.svelte";
	import MatrixScatterplot from "./MatrixScatterplot.svelte";
	import AdvancedSettings from "./AdvancedSettings.svelte";
	import SelectionManager from "./SelectionManager.svelte";
	import LinePlot from "./LinePlot.svelte";
	let visible: boolean = true;
	import ResidenceOverview from "./ResidenceOverview.svelte";
    import { residencesSelected } from "./selectiondata.js";
</script>

<main>
	<div id="header">
		<Container fluid
			><Row>
				<Col><h1>Visualizing the Aarhusian property market</h1></Col></Row
			></Container
		>
	</div>
	<Container fluid>
		<Row>
			<Col></Col>
		</Row>
		<Row>
			<Col lg="5" style="border-right: solid lightgrey 2px;">
				<Row>
					<Map />
				</Row>
				<Row>
					{#if $selectedResidence.ID}
						<ResidenceOverview residence={$selectedResidence} />
					{:else}
						<h2>Click a residence to show more info...</h2>
					{/if}
				</Row>
			</Col>
			<Col lg="5" style="border-right: solid lightgrey 2px;">
				<Row>
					<MatrixScatterplot />
				</Row>
				<Row>
					<LinePlot />
				</Row>
			</Col>
			<Col lg="2">
				<SelectionManager />
				<FilterManager />
				<AdvancedSettings />
				Number of residences: {$residencesFiltered.length}
				{#if $residencesSelected.length != $residencesFiltered.length}
				Number of selected residences: {$residencesSelected.length}
				{/if}
			</Col>
		</Row>
	</Container>
	<div class="toast-container position-fixed bottom-0 end-0 p-3">
		{#if $updatingResidences || $updatingPostcodes || $updatingParishes || $updatingHistoricalSales}
			<Toast class="me-1">
				<ToastHeader><Spinner size="sm" /> Loading</ToastHeader>
				<ToastBody>
					{#if $updatingResidences}
						Current residence data... <br />
					{/if}
					{#if $updatingPostcodes}
						Aggregated postcode data... <br />
					{/if}
					{#if $updatingParishes}
						Aggregated parish data... <br />
					{/if}
					{#if $updatingHistoricalSales}
						Historical sales data... <br />
					{/if}
				</ToastBody>
			</Toast>
		{/if}
		{#if $salesTruncated && visible}
			<Toast class="me-1">
				<ToastHeader style="display: block">
					Historical data truncated
					<!-- svelte-ignore a11y-click-events-have-key-events -->
					<span
						style="float: right; cursor: pointer;"
						class="dismissButton"
						on:click={() => {
							visible = false;
						}}>❌</span
					>
				</ToastHeader>
				<ToastBody>
					The historical data view has been truncated for performance reasons.<br
					/>
					Filter for a smaller dataset to fix.
				</ToastBody>
			</Toast>
		{/if}
	</div>
</main>

<hr />
<footer>
	<Container fluid
		><Row>
			<Col>
				A visualisation of housing prices in the Aarhus area.<br />
				<i
					>Carl Ulsøe Christensen, Freja Østerbøg, Gaia Bøhm Andresen & Sarah
					Fjelsted Alrøe</i
				>
			</Col></Row
		></Container
	>
</footer>

<style>
	#header {
		background-color: rgb(208, 180, 235);
		color: black;
		margin-bottom: 1rem;
	}
	#header h1 {
		margin-top: 0;
		padding: 0.5rem;
	}
	h1 {
		margin-bottom: 0.1em;
		margin-top: 0.1em;
	}
</style>
