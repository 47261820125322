<script lang="ts">
    import * as d3 from "d3";
    import {onMount} from "svelte";
    import {residencesFiltered, selectedResidence} from "./filterdata.js";
    import {residencesSelected} from "./selectiondata.js";
    import {selectionList} from "./selection.js";
    import {
        getName,
        propertyTypeColors,
        propertyTypeNames,
        availableAttributesIndexByName,
        availableAttributes
    } from "./structure.js";
    import {filterList} from "./filter.js";

    let brush = d3.brush().on("start brush", handleBrush);

    let brushExtent;
    const propertytype = "PropertyType";

    function handleBrush(e) {
        brushExtent = e.selection;
        updateSelection();
    }

    function initBrush() {
        d3.select("#ScatterPlot-" + x_axis_prop + "-" + y_axis_prop + ">#brushcontainer").call(
            brush,
        );
    }

    function updateSelection() {
        // Make coordinates into rule
        if (
            brushExtent[1][0] - brushExtent[0][0] < 3 &&
            brushExtent[0][1] - brushExtent[1][1] < 3
        ) {
            selectionList.set([]);
            return;
        }
        let rule1NameIndex = availableAttributesIndexByName[x_axis_prop];
        let rule1Min = isNaN(rule1NameIndex) ? -Infinity : availableAttributes[rule1NameIndex].min;
        let rule1Max = isNaN(rule1NameIndex) ? Infinity : availableAttributes[rule1NameIndex].max;
        let rule1 = {
            name: x_axis_prop,
            min: Math.max(x.invert(brushExtent[0][0]), isNaN(rule1Min) ? -Infinity : rule1Min),
            max: Math.min(x.invert(brushExtent[1][0]), isNaN(rule1Max) ? Infinity : rule1Max),
        };
        let rule2NameIndex = availableAttributesIndexByName[y_axis_prop];
        let rule2Min = isNaN(rule2NameIndex) ? -Infinity : availableAttributes[rule2NameIndex].min;
        let rule2Max = isNaN(rule2NameIndex) ? Infinity : availableAttributes[rule2NameIndex].max;
        let rule2 = {
            name: y_axis_prop,
            min: Math.max(y.invert(brushExtent[1][1]), isNaN(rule2Min) ? -Infinity : rule2Min),
            max: Math.min(y.invert(brushExtent[0][1]), isNaN(rule2Max) ? Infinity : rule2Max),
        };

        let rules = [rule1, rule2];
        // Change selection
        selectionList.set(rules);
    }

    $: {
        if (brushExtent && $selectionList.length == 0) {
            svg.select(".selection").attr("width", 0).attr("height", 0);
        }
    }

    const width = 975;
    const height = 610;
    let gy;
    let gx;
    // set the dimensions and margins of the graph
    var margin = {top: 10, right: 40, bottom: 40, left: 90};

    export let x_axis_prop = "Area";
    export let y_axis_prop = "EvalPrice";
    let svg;

    function addXAxis(svg, x) {
        if (svg == undefined) return;
        gx = svg
            .append("g")
            .attr("transform", "translate(0," + (height - 30) + ")")
            .call(d3.axisBottom(x));
    }

    function addYAxis(svg, y) {
        if (svg == undefined) return;
        gy = svg
            .append("g")
            .attr("transform", "translate(90,0)")
            .call(d3.axisLeft(y));
    }

    let x, y;
    let extent_of_x, extent_of_y;
    $: {
        if ($residencesFiltered != undefined) {
            extent_of_x = d3.extent(
                $residencesFiltered.map((item) => item[x_axis_prop]),
            );
            x = d3
                .scaleLinear()
                .domain(extent_of_x)
                .range([90, width - 30]);
            extent_of_y = d3.extent(
                $residencesFiltered.map((item) => item[y_axis_prop]),
            );
            y = d3
                .scaleLinear()
                .domain(extent_of_y)
                .range([height - 30, 30]);
        } else {
            extent_of_x = [0, 10000];
            extent_of_y = [0, 10000];
        }
    }

    $: {
        console.log(y_axis_prop); // TODO: This is a temporary fix to invoke axis update on axis prop change
        console.log(x_axis_prop);

        if (extent_of_y[1] != 0) {
            if (gy) {
                gy.transition().duration(750).call(d3.axisLeft(y));
            }
        }

        if (extent_of_x[1] != 0) {
            if (gx) {
                gx.transition().duration(750).call(d3.axisBottom(x));
            }
        }
    }

    onMount(async function (): Promise<void> {
        svg = d3.select("#ScatterPlot-" + x_axis_prop + "-" + y_axis_prop);

        addYAxis(svg, y);
        addXAxis(svg, x);
        initBrush();
    });

    function togglePropertyTypeFilter(propType) {
        let filterIndex = $filterList.findIndex((element) => element.name == "PropertyType");
        let propertyTypeFilter;
        if (filterIndex != -1) {
            propertyTypeFilter = $filterList[filterIndex];
            if (propertyTypeFilter.in.indexOf(propType) == -1) {
                propertyTypeFilter.in.push(propType);
            } else {
                propertyTypeFilter.in.splice(propertyTypeFilter.in.indexOf(propType), 1);
                if (propertyTypeFilter.in.length == 0) {
                    filterList.update((filter => {
                        filter.splice(filter.findIndex((element) => element.name == "PropertyType"), 1);
                        return filter;
                    }))
                    return;
                }
            }
            filterList.update((filter => {
                filter.splice(filter.findIndex((element) => element.name == "PropertyType"), 1);
                filter.push(propertyTypeFilter);
                return filter;
            }))
        } else {
            propertyTypeFilter = {name: "PropertyType", in: [propType]};
            filterList.update((filter) => {
                filter.push(propertyTypeFilter);
                return filter;
            })
        }
    }
</script>

<main>
    <svg
            viewBox="0 0 {width} {height}"
            id="ScatterPlot-{x_axis_prop}-{y_axis_prop}"
    >
        <g id="brushcontainer">

        </g>
        <g>
            <g id="dots-{x_axis_prop}-{y_axis_prop}">
                {#each $residencesFiltered as residence (residence.ID)}
                    <!-- svelte-ignore a11y-click-events-have-key-events -->
                    <circle
                            id={residence.ID +
                        "-circle-" +
                        x_axis_prop +
                        "-" +
                        y_axis_prop}
                            cx={x(residence[x_axis_prop])}
                            cy={y(residence[y_axis_prop])}
                            r="2.5"
                            style="cursor:pointer; fill:grey"
                            on:click={()=>{selectedResidence.set(residence)}}
                    />
                {/each}
                <!-- TODO make not plot on top -->
                {#each $residencesSelected as residence (residence.ID)}
                    <!-- svelte-ignore a11y-click-events-have-key-events -->
                    <circle
                            id={residence.ID +
                        "-circle-" +
                        x_axis_prop +
                        "-" +
                        y_axis_prop}
                            cx={x(residence[x_axis_prop])}
                            cy={y(residence[y_axis_prop])}
                            r="2.5"
                            style={"cursor:pointer; fill:"+propertyTypeColors[residence["PropertyType"]]+";"}
                            on:click={()=>{selectedResidence.set(residence)}}
                    />
                {/each}
                {#if $selectedResidence}
                    <circle
                            cx={x($selectedResidence[x_axis_prop])}
                            cy={y($selectedResidence[y_axis_prop])}
                            style="cursor: pointer; fill: {propertyTypeColors[$selectedResidence[propertytype]]}; stroke:white;"
                            r="10"
                    />
                {/if}
            </g>
            <g></g>
        </g>

        <text x={width} y={height - margin.top - 30} text-anchor="end" style="fill:black">
            {getName(x_axis_prop)}
        </text>
        <text x={-margin.top} y={margin.left + 20} transform="rotate(-90)" text-anchor="end" style="fill:black">
            {getName(y_axis_prop)}
        </text>
    </svg>
    <svg
            viewBox="0 0 {width} {height}"
            style="position: absolute; top: 2rem; left: 0; pointer-events: none;"
    >
        <g style="pointer-events: all;">
            <!-- svelte-ignore a11y-click-events-have-key-events -->
            <text class="propertyType" x={width-35} y={30} text-anchor="end" style="fill:black"
                  on:click={()=>togglePropertyTypeFilter(1)}>{propertyTypeNames[1]}</text>
            <!-- svelte-ignore a11y-click-events-have-key-events -->
            <rect class="propertyType" x={width-30} y={30-15} width=16 height=16 style={"fill:"+propertyTypeColors[1]}
                  on:click={()=>togglePropertyTypeFilter(1)}></rect>
            <!-- svelte-ignore a11y-click-events-have-key-events -->
            <text class="propertyType" x={width-35} y={50} text-anchor="end" style="fill:black"
                  on:click={()=>togglePropertyTypeFilter(3)}>{propertyTypeNames[3]}</text>
            <!-- svelte-ignore a11y-click-events-have-key-events -->
            <rect class="propertyType" x={width-30} y={50-15} width=16 height=16 style={"fill:"+propertyTypeColors[3]}
                  on:click={()=>togglePropertyTypeFilter(3)}></rect>
            <!-- svelte-ignore a11y-click-events-have-key-events -->
            <text class="propertyType" x={width-35} y={70} text-anchor="end" style="fill:black"
                  on:click={()=>togglePropertyTypeFilter(6)}>{propertyTypeNames[6]}</text>
            <!-- svelte-ignore a11y-click-events-have-key-events -->
            <rect class="propertyType" x={width-30} y={70-15} width=16 height=16 style={"fill:"+propertyTypeColors[6]}
                  on:click={()=>togglePropertyTypeFilter(6)}></rect>
        </g>
    </svg>
</main>

<style>
    #ScatterPlot {
        background-color: aliceblue;
    }

    .propertyType {
        cursor: pointer;
    }
</style>
