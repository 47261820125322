import { writable } from 'svelte/store';
import { residencesFiltered, postcodesFiltered, parishesFiltered } from "./filterdata.js"
import { selectionList } from "./selection.js";

export let residencesSelected  = writable([]);
export let postcodesSelected = writable({0: 0});
export let parishesSelected = writable({0: 0});
let residencesSelectedValue;
let residencesFilteredValue;
let parishesFilteredValue;
let postcodeFilteredValue;
let curSelection;

function isValid(residence, selection) {
    //return residence["EvalPrice"] < 2000000;
    for (const rule of selection) {
        let att = rule.name;
        if (rule.min && residence[att] < rule.min) {
            return false;
        }
        if (rule.max && residence[att] > rule.max) {
            return false;
        }
        if (rule.in && rule.in.length && (!rule.in.includes(residence[att]))) {
            return false;
        }
    }
    return true;
}

function updateFilteredResidences(selection){
    residencesSelectedValue = residencesFilteredValue.filter((residence) => isValid(residence, selection)); 
    residencesSelected.set(residencesSelectedValue);
}


function unique(value, index, array) {
    return array.indexOf(value) === index;
}

function updateSelectedPostcode(){
    let selectedCodes = residencesSelectedValue.map(v => v["Postcode"]).filter(unique);
    let postcodeValues = {};
    selectedCodes.forEach(element => {
        postcodeValues[element] = postcodeFilteredValue[element]
    });
    postcodesSelected.set(postcodeValues);
}

function updateSelectedParishes(){
    let selectedParishes = residencesSelectedValue.map(v => v["Parish"]).filter(unique);
    let parishValues = {}
    selectedParishes.forEach(element => {
        parishValues[element] = parishesFilteredValue[element]
    });
    parishesSelected.set(parishValues);
}


postcodesFiltered.subscribe((value) => {
    postcodeFilteredValue = value;
    if (residencesSelectedValue) {
        updateSelectedParishes();
        updateSelectedPostcode();
    }
})

parishesFiltered.subscribe((value) => {
    parishesFilteredValue = value;
    if (residencesSelectedValue) {
        updateSelectedParishes();
        updateSelectedPostcode();
    }
})

residencesFiltered.subscribe((value) => {
    residencesFilteredValue = value;
    updateFilteredResidences(curSelection);
    updateSelectedParishes();
    updateSelectedPostcode();
})

selectionList.subscribe((selection)=>{
    updateFilteredResidences(selection);
    curSelection = selection;
    updateSelectedParishes();
    updateSelectedPostcode();
})
