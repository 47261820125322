import { writable, get } from 'svelte/store';


export const availableAttributes = [
  {
    prettyName: "Floor space [m²]",
    colName: "Area",
    plottable: true,
    defaultMatrix: true,
    min: 0,
  },
  {
    prettyName: "Lot size [m²]",
    colName: "LotSize",
    plottable: true,
    defaultMatrix: true,
    min: 0,
  },
  {
    prettyName: "Current Evaluation Price [dkk]",
    colName: "EvalPrice",
    plottable: true,
    defaultMatrix: true,
    min: 0,
  },
  {
    prettyName: "Latest Sale Price [dkk]",
    colName: "LatestSale",
    plottable: true,
    defaultMatrix: true,
    min: 0,
  },
  {
    prettyName: "Latest Sale Price, Inflation adjusted [dkk]",
    colName: "LatestSaleAdj",
    plottable: true,
    min: 0,
  },
  {
    prettyName: "Property Tax [dkk/yr]", //TODO check if per year
    colName: "PropertyTax",
    plottable: true,
    min: 0,
  },
  {
    prettyName: "Construction Year",
    colName: "ConstructionYear",
    plottable: true,
    min: 1500,
  },
  {
    prettyName: "Number of rooms",
    colName: "Rooms",
    plottable: true,
    min: 0,
  },
  {
    prettyName: "Height above sea level",
    colName: "Height",
    plottable: true,
    min: 0,
  },
  {
    prettyName: "Basement size [m²]",
    colName: "BasementSize",
    plottable: true,
    min: 0,
  },
  {
    prettyName: "Postcode",
    colName: "Postcode",
    plottable: false,
    min: 1001,
  },

]

export const availableAttributeNames = availableAttributes.map((elm) => elm.colName);
export const availableAttributesIndexByName = availableAttributes.reduce((obj, attr, i) => ({...obj, [attr.colName]: i}), {});

export const availablePrettyNames = [
  {
    prettyName: "Evaluation Date",
    colName: "Year",
  },
  {
    prettyName: "Land Value - Inflation adjusted",
    colName: "LandValueAdj",
  },
  {
    prettyName: "Land Value",
    colName: "LandValue",
  },
  {
    prettyName: "Property Value - Inflation adjusted",
    colName: "PropertyValueAdj",
  },
  {
    prettyName: "Property Value",
    colName: "PropertyValue",
  },
  {
    prettyName: "Sale price",
    colName: "Price",
    plottable: false,
    min: 0,
  },
  {
    prettyName: "Sale price - Inflation adjusted",
    colName: "PriceAdj",
    plottable: false,
    min: 0,
  },
  {
    prettyName: "Sale date",
    colName: "Recalculation",
    plottable: false,
    min: 0,
  },
]

export const propertyTypeNames ={
  1: "House",
  3: "Freehold flat",
  6: "Farm"
}

export const propertyTypeColors ={ // Color-set is 3-class Dark2 by colorbrewer2.
  1: "#1b9e77",
  3: "#d95f02",
  6: "#7570b3"
}
export const sequentialTypeColors = "Greens";

export const derivedProperties = writable([
  {colName:"ppsqm", prettyName:"Price per square meter [dkk/m²]", inputA:"EvalPrice", inputB:"Area", operator:"/"},
  {colName:"ppr", prettyName:"Price per rooms [dkk/room]", inputA:"EvalPrice", inputB:"Rooms", operator:"/"}
])


export function getName(element) {
  if (typeof element == "string"){
    element = {name:element}
  }
  if(element.prettyName) {
    return element.prettyName;
  }
  for (const attr of availableAttributes) {
    if (element.name == attr.colName) {
      return attr.prettyName
    }
  }
  for (const attr of get(derivedProperties)) {
    if (element.name == attr.colName) {
      return attr.prettyName
    }
  }
  if (element.name) {
    return element.name
  }
  return "Error: No name";
}

export const PostcodeDict = {
  8000:"Aarhus C",
  8200:"Aarhus N",
  8210:"Aarhus V",
  8220:"Brabrand",
  8230:"Åbyhøj",
  8240:"Risskov",
  8250:"Egå",
  8260:"Viby J",
  8270:"Højbjerg",
  8300:"Odder",
  8305:"Samsø",
  8310:"Tranbjerg J",
  8320:"Mårslet",
  8330:"Beder",
  8340:"Malling",
  8355:"Solbjerg",
  8361:"Hasselager",
  8362:"Hørning",
  8380:"Trige",
  8381:"Tilst",
  8382:"Hinnerup",
  8462:"Harlev J",
  8464:"Galten",
  8471:"Sabro",
  8520:"Lystrup",
  8530:"Hjortshøj",
  8541:"Skødstrup",
  8543:"Hornslet",
  8660:"Skanderborg",
}