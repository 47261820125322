import { writable } from 'svelte/store';

export const postCodeFilter2 = {
    name: "Postcode",
    min: null,
    max: null,
    in: [8200, 8220, 8462, 8362, 8270]
}

export const filterList = writable([postCodeFilter2]);

export const locationsToSearch = [
{Address: 'Elstedvej 89, Terp, 8200 Aarhus N', Lat: 56.22875403, Long: 10.19746401},
{Address: 'Elstedvej 96, Terp, 8200 Aarhus N', Lat: 56.22866804, Long: 10.20516767},
{Address: 'Høvej 26, 8200 Aarhus N', Lat: 56.23528854, Long: 10.20585479},
{Address: 'Høvej 34, Elev, 8200 Aarhus N', Lat: 56.23322051, Long: 10.20830053},
{Address: 'Høvej 41A, Elev, 8200 Aarhus N', Lat: 56.23107733, Long: 10.21245149},
{Address: 'Høvej 53, Elev, 8200 Aarhus N', Lat: 56.2296173, Long: 10.21564675},
{Address: 'Høvej 55, Elev, 8200 Aarhus N', Lat: 56.22957332, Long: 10.21600734},
{Address: 'Høvej 65, Elev, 8200 Aarhus N', Lat: 56.2281012, Long: 10.21814025},
{Address: 'Høvej 67, Elev, 8200 Aarhus N', Lat: 56.22783455, Long: 10.21875684},
{Address: 'Høvej 58B, Elev, 8200 Aarhus N', Lat: 56.2289078, Long: 10.21593141},
{Address: 'Høvej 58A, Elev, 8200 Aarhus N', Lat: 56.22894136, Long: 10.21567083},
{Address: 'Høvej 26A, 8200 Aarhus N', Lat: 56.23553389, Long: 10.20568954},
{Address: 'Glamhøjvej 4, Lyngby, 8220 Brabrand', Lat: 56.16910377, Long: 10.04318407},
{Address: 'Glamhøjvej 5, Lyngby, 8220 Brabrand', Lat: 56.16937579, Long: 10.04289141},
{Address: 'Glamhøjvej 6, Lyngby, 8220 Brabrand', Lat: 56.16896958, Long: 10.04364636},
{Address: 'Glamhøjvej 8, Lyngby, 8220 Brabrand', Lat: 56.16894816, Long: 10.04405453},
{Address: 'Glamhøjvej 9, Lyngby, 8220 Brabrand', Lat: 56.16914045, Long: 10.04416041},
{Address: 'Glamhøjvej 12, Lyngby, 8220 Brabrand', Lat: 56.16890335, Long: 10.04457255},
{Address: 'Glamhøjvej 29, Lyngby, 8220 Brabrand', Lat: 56.17127753, Long: 10.05174598},
{Address: 'Glamhøjvej 31, Lyngby, 8220 Brabrand', Lat: 56.17245982, Long: 10.05271652},
{Address: 'Lyngbygårdsvej 2, Lyngby, 8220 Brabrand', Lat: 56.16712191, Long: 10.02932269},
{Address: 'Lyngbygårdsvej 9, Lyngby, 8220 Brabrand', Lat: 56.16443374, Long: 10.02951247},
{Address: 'Lyngbygårdsvej 28, Lyngby, 8220 Brabrand', Lat: 56.16955664, Long: 10.04222294},
{Address: 'Lyngbygårdsvej 28A, Lyngby, 8220 Brabrand', Lat: 56.16956328, Long: 10.0425019},
{Address: 'Lyngbygårdsvej 28B, Lyngby, 8220 Brabrand', Lat: 56.16920163, Long: 10.04383191},
{Address: 'Lyngbygårdsvej 30, Lyngby, 8220 Brabrand', Lat: 56.16988952, Long: 10.04270514},
{Address: 'Lyngbygårdsvej 31, Lyngby, 8220 Brabrand', Lat: 56.16951362, Long: 10.04146465},
{Address: 'Lyngbygårdsvej 32, Lyngby, 8220 Brabrand', Lat: 56.16992508, Long: 10.04221778},
{Address: 'Lyngbygårdsvej 33, Lyngby, 8220 Brabrand', Lat: 56.16989971, Long: 10.04149636},
{Address: 'Lyngbygårdsvej 34, Lyngby, 8220 Brabrand', Lat: 56.17013028, Long: 10.04185902},
{Address: 'Lyngbygårdsvej 35, Lyngby, 8220 Brabrand', Lat: 56.16998968, Long: 10.04148269},
{Address: 'Lyngbygårdsvej 36, Lyngby, 8220 Brabrand', Lat: 56.17054781, Long: 10.0418924},
{Address: 'Lyngbygårdsvej 38, Lyngby, 8220 Brabrand', Lat: 56.17090043, Long: 10.0421648},
{Address: 'Lyngbygårdsvej 40A, Lyngby, 8220 Brabrand', Lat: 56.17136934, Long: 10.0435636},
{Address: 'Lyngbygårdsvej 40B, Lyngby, 8220 Brabrand', Lat: 56.17144007, Long: 10.04316786},
{Address: 'Lyngbygårdsvej 42, Lyngby, 8220 Brabrand', Lat: 56.17171954, Long: 10.04359017},
{Address: 'Lyngbygårdsvej 43A, Lyngby, 8220 Brabrand', Lat: 56.17248389, Long: 10.04245428},
{Address: 'Lyngbygårdsvej 43B, Lyngby, 8220 Brabrand', Lat: 56.17254536, Long: 10.04209049},
{Address: 'Lyngbygårdsvej 43C, Lyngby, 8220 Brabrand', Lat: 56.17257132, Long: 10.04167547},
{Address: 'Lyngbygårdsvej 43D, Lyngby, 8220 Brabrand', Lat: 56.17287441, Long: 10.04196152},
{Address: 'Lyngbygårdsvej 43E, Lyngby, 8220 Brabrand', Lat: 56.17280854, Long: 10.04231409},
{Address: 'Lyngbygårdsvej 44, Lyngby, 8220 Brabrand', Lat: 56.1715171, Long: 10.04255774},
{Address: 'Lyngbygårdsvej 45, Lyngby, 8220 Brabrand', Lat: 56.1727431, Long: 10.04261513},
{Address: 'Lyngbygårdsvej 46, Lyngby, 8220 Brabrand', Lat: 56.17185782, Long: 10.04264348},
{Address: 'Lyngbygårdsvej 46B, Lyngby, 8220 Brabrand', Lat: 56.17178173, Long: 10.04314185},
{Address: 'Lyngbygårdsvej 48, Lyngby, 8220 Brabrand', Lat: 56.17212058, Long: 10.04291636},
{Address: 'Lyngbygårdsvej 50, Lyngby, 8220 Brabrand', Lat: 56.17238418, Long: 10.04308973},
{Address: 'Selkærvej 8, Lyngby, 8220 Brabrand', Lat: 56.16944521, Long: 10.06426526},
{Address: 'Selkærvej 10, Lyngby, 8220 Brabrand', Lat: 56.16853771, Long: 10.06165126},
{Address: 'Lyngbygårdsvej 31A, Lyngby, 8220 Brabrand', Lat: 56.16970164, Long: 10.04154334},
{Address: 'Ådalsparken 44, 8462 Harlev J', Lat: 56.13586948, Long: 9.99452641},
{Address: 'Ådalsparken 38B, 8462 Harlev J', Lat: 56.13588093, Long: 9.99520582},
{Address: 'Brydehøjvej 52, 8462 Harlev J', Lat: 56.12279268, Long: 10.01634149},
{Address: 'Damkærvej 1, 8462 Harlev J', Lat: 56.14064183, Long: 9.99872461},
{Address: 'Damkærvej 2, 8462 Harlev J', Lat: 56.14051447, Long: 9.99836308},
{Address: 'Damkærvej 3, 8462 Harlev J', Lat: 56.14048835, Long: 9.9988151},
{Address: 'Damkærvej 4, 8462 Harlev J', Lat: 56.14034277, Long: 9.99848351},
{Address: 'Damkærvej 5, 8462 Harlev J', Lat: 56.14030711, Long: 9.99900449},
{Address: 'Damkærvej 6, 8462 Harlev J', Lat: 56.14019779, Long: 9.99863473},
{Address: 'Damkærvej 7, 8462 Harlev J', Lat: 56.14034024, Long: 9.99935248},
{Address: 'Damkærvej 8, 8462 Harlev J', Lat: 56.1400263, Long: 9.99872974},
{Address: 'Damkærvej 9, 8462 Harlev J', Lat: 56.14003114, Long: 9.99924275},
{Address: 'Damkærvej 10, 8462 Harlev J', Lat: 56.13985495, Long: 9.99880769},
{Address: 'Damkærvej 11, 8462 Harlev J', Lat: 56.13981036, Long: 9.99932151},
{Address: 'Damkærvej 12, 8462 Harlev J', Lat: 56.13965539, Long: 9.99904101},
{Address: 'Damkærvej 13, 8462 Harlev J', Lat: 56.13938975, Long: 9.9996677},
{Address: 'Damkærvej 14, 8462 Harlev J', Lat: 56.13950613, Long: 9.99916476},
{Address: 'Damkærvej 16, 8462 Harlev J', Lat: 56.13933563, Long: 9.99913732},
{Address: 'Edelhoffvej 1, 8462 Harlev J', Lat: 56.1425987, Long: 10.00173668},
{Address: 'Edelhoffvej 2, 8462 Harlev J', Lat: 56.14042213, Long: 10.0025541},
{Address: 'Edelhoffvej 2A, 8462 Harlev J', Lat: 56.14197885, Long: 10.00172056},
{Address: 'Edelhoffvej 2A, 8462 Harlev J', Lat: 56.14197885, Long: 10.00172056},
{Address: 'Edelhoffvej 2A, 8462 Harlev J', Lat: 56.14197885, Long: 10.00172056},
{Address: 'Edelhoffvej 3, 8462 Harlev J', Lat: 56.14258115, Long: 10.00223804},
{Address: 'Edelhoffvej 4, 8462 Harlev J', Lat: 56.14149357, Long: 10.00449617},
{Address: 'Edelhoffvej 6, 8462 Harlev J', Lat: 56.13989883, Long: 10.00555052},
{Address: 'Edelhoffvej 8A, 8462 Harlev J', Lat: 56.14048842, Long: 10.00931097},
{Address: 'Edelhoffvej 9, Højby Mark, 8462 Harlev J', Lat: 56.14163668, Long: 10.01392779},
{Address: 'Edelhoffvej 10, Højby Mark, 8462 Harlev J', Lat: 56.14013446, Long: 10.01577307},
{Address: 'Edelhoffvej 11, Højby Mark, 8462 Harlev J', Lat: 56.14042827, Long: 10.01664889},
{Address: 'Edelhoffvej 13, Højby Mark, 8462 Harlev J', Lat: 56.14054063, Long: 10.01828034},
{Address: 'Edelhoffvej 15, Højby Mark, 8462 Harlev J', Lat: 56.14036671, Long: 10.02247608},
{Address: 'Fusvadvej 21, Tåstrup, 8362 Hørning', Lat: 56.1138277, Long: 9.99564273},
{Address: 'Gammel Stillingvej 439, 8462 Harlev J', Lat: 56.14341941, Long: 10.00080539},
{Address: 'Gammel Stillingvej 441, 8462 Harlev J', Lat: 56.14327461, Long: 10.00093344},
{Address: 'Gammel Stillingvej 443, 8462 Harlev J', Lat: 56.14313107, Long: 10.00090557},
{Address: 'Gammel Stillingvej 445, 8462 Harlev J', Lat: 56.14297394, Long: 10.00089135},
{Address: 'Gammel Stillingvej 446, 8462 Harlev J', Lat: 56.14322483, Long: 10.00042308},
{Address: 'Gammel Stillingvej 447, 8462 Harlev J', Lat: 56.14282555, Long: 10.00090874},
{Address: 'Gammel Stillingvej 448, 8462 Harlev J', Lat: 56.14305192, Long: 10.00013903},
{Address: 'Gammel Stillingvej 449, 8462 Harlev J', Lat: 56.14266813, Long: 10.00092975},
{Address: 'Gammel Stillingvej 450, 8462 Harlev J', Lat: 56.14281797, Long: 10.00017915},
{Address: 'Gammel Stillingvej 450A, 8462 Harlev J', Lat: 56.14260565, Long: 9.99976919},
{Address: 'Gammel Stillingvej 452, 8462 Harlev J', Lat: 56.14238554, Long: 10.00031985},
{Address: 'Gammel Stillingvej 455, 8462 Harlev J', Lat: 56.14185424, Long: 10.00103124},
{Address: 'Gammel Stillingvej 457, 8462 Harlev J', Lat: 56.14171037, Long: 10.00103265},
{Address: 'Gammel Stillingvej 458, 8462 Harlev J', Lat: 56.1413588, Long: 10.00006481},
{Address: 'Gammel Stillingvej 459, 8462 Harlev J', Lat: 56.14155476, Long: 10.00138556},
{Address: 'Gammel Stillingvej 460, 8462 Harlev J', Lat: 56.14133297, Long: 10.00047968},
{Address: 'Gammel Stillingvej 461, 8462 Harlev J', Lat: 56.14139701, Long: 10.00089366} ]