<script lang="ts">
  import { availableAttributes, derivedProperties, propertyTypeColors, propertyTypeNames } from "./structure.js";
  import { Button, ButtonGroup, Modal } from "sveltestrap";
  import ScatterPlot from "./ScatterPlot.svelte";
  import ScatterPlotCanvas from "./ScatterPlotCanvas.svelte";
  import DensityMap from "./DensityMap.svelte";
  import BinnedScatterplot from "./BinnedScatterplot.svelte";
  import { userLocations } from "./filterdata.js";
  import Histogram from "./Histogram.svelte";
    import { filterList } from "./filter.js";

  const SCATTER = 0, DENSITY = 1, BINNED = 2, HISTOGRAM = 3;

  let availableAxes = availableAttributes.filter(
    (attribute) => attribute.plottable
  );
  let availableDerived = $derivedProperties.map(axis=>{axis.selected=false; return axis});
  let availableLocations = $userLocations.map(axis=>{axis.selected=false; return axis});

  availableAxes = availableAxes.map(axis=>{axis.selected=axis.defaultMatrix; return axis});
  derivedProperties.subscribe((newAvailable)=>{
    availableDerived = newAvailable.map(axis=>{axis.selected=false; return axis});
  })
  userLocations.subscribe((newAvailable)=>{
    availableLocations = newAvailable.map(axis=>{axis.selected=false; return axis});
  })

  let chosenAxes:{colName:string,prettyName:string,index?:number}[] = availableAxes.filter(axis=>axis.selected).map(axis=>{return {colName: axis.colName, prettyName: axis.prettyName}});
  $: {
    chosenAxes = availableAxes.filter(axis=>axis.selected).map(axis=>{return {colName: axis.colName, prettyName: axis.prettyName}})
    .concat(availableDerived.filter(axis=>axis.selected).map(axis=>{return {colName: axis.colName, prettyName: axis.prettyName}}))
    .concat(availableLocations.filter(axis=>axis.selected).map(axis=>{return {colName: axis.name, prettyName: axis.prettyName}}));
    chosenAxes.forEach((axis,i)=>{axis.index = i});
  }
  
  let selectionModalOpen = false;
  const toggleSelectionModal = () => (selectionModalOpen = !selectionModalOpen);

  let scatterplotSelected = false;
  let selectedX, selectedY:string;
  let plotSelection = SCATTER;

  function togglePropertyTypeFilter(propType){
    let filterIndex = $filterList.findIndex((element)=>element.name=="PropertyType");
    let propertyTypeFilter;
    if (filterIndex != -1){
      propertyTypeFilter = $filterList[filterIndex];
      if (propertyTypeFilter.in.indexOf(propType)==-1){
        propertyTypeFilter.in.push(propType);
      }else{
        propertyTypeFilter.in.splice(propertyTypeFilter.in.indexOf(propType),1);
        if (propertyTypeFilter.in.length == 0) {
            filterList.update((filter=>{
                filter.splice(filter.findIndex((element)=>element.name=="PropertyType"),1);
                return filter;
            }))
            return;
        }
      }
      filterList.update((filter=>{
        filter.splice(filter.findIndex((element)=>element.name=="PropertyType"),1);
        filter.push(propertyTypeFilter);
        return filter;
      }))
    }else{
      propertyTypeFilter = {name:"PropertyType", in:[propType]};
      filterList.update((filter)=>{
        filter.push(propertyTypeFilter);
        return filter;
      })
    }
}
</script>

<main>
  {#if scatterplotSelected}
  <ButtonGroup size="sm">
    <Button active={plotSelection === SCATTER} on:click={()=>{plotSelection = SCATTER}}>Scatter</Button>
    <Button active={plotSelection === DENSITY} on:click={()=>{plotSelection = DENSITY}}>Density</Button>
    <Button active={plotSelection === BINNED} on:click={()=>{plotSelection = BINNED}}>Binned</Button>
    {#if (selectedX === selectedY)}
    <Button active={plotSelection === BINNED} on:click={()=>{plotSelection = HISTOGRAM}}>Histogram</Button>
    {/if}
  </ButtonGroup>
  <div class="floatingButton">
    <Button size="sm" on:click={()=>scatterplotSelected=false}>🔙</Button>
  </div>
  {#if plotSelection === SCATTER}
    <ScatterPlot x_axis_prop={selectedX} y_axis_prop={selectedY}/>
  {:else if plotSelection === DENSITY}
    <DensityMap x_axis_prop={selectedX} y_axis_prop={selectedY}/>
  {:else if plotSelection === BINNED}
    <BinnedScatterplot x_axis_prop={selectedX} y_axis_prop={selectedY}/>
  {:else}
    <Histogram x_axis_prop={selectedX}/>
  {/if}
  {:else}
  <!-- svelte-ignore a11y-click-events-have-key-events -->
  <div style="float:left; margin-right:0.5rem; cursor:pointer;"  on:click={()=>togglePropertyTypeFilter(1)}><span style={"color: "+propertyTypeColors[1]}>■</span> {propertyTypeNames[1]}</div>
  <!-- svelte-ignore a11y-click-events-have-key-events -->
  <div style="float:left; margin-right:0.5rem; cursor:pointer;"  on:click={()=>togglePropertyTypeFilter(3)}><span style={"color: "+propertyTypeColors[3]}>■</span> {propertyTypeNames[3]}</div>
  <!-- svelte-ignore a11y-click-events-have-key-events -->
  <div style="float:left; margin-right:0.5rem; cursor:pointer;"  on:click={()=>togglePropertyTypeFilter(6)}><span style={"color: "+propertyTypeColors[6]}>■</span> {propertyTypeNames[6]}</div>
  <Button style="float: right; margin-bottom:0" size="sm" on:click={toggleSelectionModal}>✏️</Button>
<div class="grid" style="clear:both; grid-template-columns: 3em repeat({chosenAxes.length},1fr); grid-template-rows: repeat({chosenAxes.length},1fr) 3em;"
>
  {#each chosenAxes as yProperty (yProperty.colName)}
    <div class="yLabel">{yProperty.prettyName}</div>
    {#each chosenAxes as xProperty (xProperty.colName)}
    <!-- svelte-ignore a11y-click-events-have-key-events -->
    <div class="cell" on:click={()=>{selectedX=xProperty.colName; selectedY=yProperty.colName;
      plotSelection=xProperty.index > yProperty.index?SCATTER:(xProperty.index < yProperty.index?DENSITY:HISTOGRAM);
      scatterplotSelected=true;}}>
      {#if xProperty.index === yProperty.index}
       <Histogram x_axis_prop={xProperty.colName}/>
      {:else if xProperty.index > yProperty.index}
      <ScatterPlotCanvas x_axis_prop={xProperty.colName} y_axis_prop={yProperty.colName}/>
      {:else}
        <DensityMap x_axis_prop={xProperty.colName} y_axis_prop={yProperty.colName} isInMatrix={true} />
      {/if}
    </div>
    {/each}
  {/each}
  <div> </div>
  {#each chosenAxes as xProperty (xProperty.colName)}
    <div class="xLabel">{xProperty.prettyName}</div>
  {/each}
  <Modal body header="Select properties to view" isOpen={selectionModalOpen} toggle={toggleSelectionModal}>
    {#each availableAxes as axis (axis.colName)}
      {axis.prettyName} <input type="checkbox" bind:checked={axis.selected} /><br>
    {/each}
    {#each availableDerived as axis (axis.colName)}
      {axis.prettyName} <input type="checkbox" bind:checked={axis.selected} /><br>
    {/each}
    {#each availableLocations as axis (axis.name)}
      {axis.prettyName} <input type="checkbox" bind:checked={axis.selected} /><br>
    {/each}
  </Modal>
</div>
{/if}
</main>

<style>
  .grid {
    display: grid;
    aspect-ratio: 4/3;
  }
  main{
    position: relative;
    margin-top: 0.5rem;
  }
  .cell{
    cursor: pointer;
    aspect-ratio: 4/3;
    overflow: hidden;
  }
  .floatingButton{
    position: absolute;
    top: 0em;
    right: 0.5rem;
  }
  .yLabel{
    writing-mode: sideways-lr;
    text-align: center;
  }
  .xLabel{
    text-align: center;
  }
</style>
