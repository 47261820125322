<script lang="ts">
  import { Button, Card, CardBody, CardHeader, CardTitle } from "sveltestrap";
  import { selectionList } from "./selection";
  import { filterList } from "./filter";
  import { getName } from "./structure";

  function updateFilterWithRule(filter, rule) {
    for (let filterRule of filter) {
      if (filterRule.name == rule.name) {
        if (rule.min > filterRule.min) {
          filterRule.min = rule.min;
        }
        if (rule.max < filterRule.max) {
          filterRule.max = rule.max
        }
        if (rule.in) {
          filterRule.in = rule.in; //TODO is this what we want?
        }
        return filter;
      } 
    }
    filter.push(rule);
    return filter;
  }

  function getCombinedFilter(filter, update) {
    for (let rule of update) {
      updateFilterWithRule(filter, rule);
    }
    return filter;
  }
</script>

{#if $selectionList.length>0}
<Card>
  <CardHeader>
    <CardTitle>
      Current selection:
    </CardTitle>
  </CardHeader>
  <CardBody>
    {#each $selectionList as selection}
      {getName(selection)}: [{selection.min.toFixed(0)} - {selection.max.toFixed(0)}] <br/>
    {/each}
    <Button color="primary" on:click={()=>{filterList.update((filter)=>getCombinedFilter(filter, $selectionList)); selectionList.set([]); }}>Apply</Button>
    <Button color="secondary" on:click={()=>{selectionList.set([])}}>Deselect</Button>
  </CardBody>
</Card>
<br/>
{/if}