<script lang="ts">
    import * as Plot from "@observablehq/plot";
    import { residencesFiltered } from "./filterdata.js";
    import { residencesSelected } from "./selectiondata.js";
    import { extent } from "d3";
    import { sequentialTypeColors} from "./structure.js";

    export let x_axis_prop = "Area";
    export let y_axis_prop = "EvalPrice";
    export let isInMatrix = false;
    let div;

    $: {
        div?.firstChild?.remove(); // remove old chart, if any

        let extent_of_x = $residencesFiltered.map((item) => item[x_axis_prop]);
        let extent_of_y = $residencesFiltered.map((item) => item[y_axis_prop]);

        const plot = Plot.plot({
            x: { domain: extent(extent_of_x), grid: true },
            y: { domain: extent(extent_of_y), tickFormat: "s" },
            color: {
                legend: !isInMatrix,
                scheme: sequentialTypeColors,
            },
            style: { background: "white", width:"100%"},
            inset: 10,
            marks: [
                Plot.frame({ fill: "white", opacity: 1 }),
                Plot.density(
                    $residencesSelected.length > 0
                        ? $residencesSelected
                        : $residencesFiltered,
                    {
                        x: x_axis_prop,
                        y: y_axis_prop,
                        stroke: "density",
                    },
                ),
            ],
        });
        div?.append(plot);
    }
</script>

<div bind:this={div} />

<style>
</style>
