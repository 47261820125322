<script lang="ts">
  import { PostcodeDict } from "./structure.js";
  import { format, formatLocale } from "d3";
  const locale = formatLocale({
    decimal: ",",
    thousands: ".",
    grouping: [3],
    currency: ["", " dkk"],
  });
  const formatCurrency = locale.format("$.3s");
  export let residence = {};
  let residenceRow;
  $: residenceRow = residence;
</script>

<div>
  <h2>Selected: {residenceRow.Streetname} {residenceRow.Streetnumber}</h2>
  <i>{residenceRow.Postcode} {PostcodeDict[residenceRow.Postcode]}</i><br />
  {residenceRow.BuildingUsage}<br />
  Constructed in {residenceRow.ConstructionYear}{#if residenceRow.ConstructionYear !== residenceRow.LatestRebuildYear},
    renovated in {residenceRow.LatestRebuildYear}{/if}<br />
  Floor size: {residenceRow.Area} m²<br />
  Lot size: {residenceRow.LotSize} m²<br />
  Heating: {residenceRow.HeatingSupply}<br />
  Construction: {residenceRow.OuterWall}, {residenceRow.Roof} <br />
  Property tax: {formatCurrency(residenceRow.PropertyTax)}/year <br />
  Current evaluation: {formatCurrency(residenceRow.EvalPrice)} <br />
  Latest sale: on {new Date(residenceRow.LatestSaleDate).toDateString()}, for {formatCurrency(residenceRow.LatestSale)} <br />
  <a
    href="https://www.google.com/maps/place/{residenceRow.Address}"
    target="_blank">On Google Maps</a
  >
  {#if residenceRow.ImageURL !== ""}
    <br /> <img src={residenceRow.ImageURL} alt="The residence" />
  {/if}
</div>
