
import { emptyPostcodeValues, emptyParishValues, db, emptyPostcodePairs, emptyParishPairs } from "./db.js";
import { filterList } from "./filter.js";
import { get, writable } from 'svelte/store';
import { derivedProperties, availableAttributeNames, propertyTypeColors } from "./structure.js";
import { getDistance } from 'geolib';

export let postcodesFiltered  = writable(emptyPostcodeValues);
export let residencesFiltered  = writable([]);
export let parishesFiltered = writable(emptyParishValues);
export let salesFiltered = writable([]);
export let evaluationsFiltered = writable([]);
export let salesTruncated = writable(false);
export let evaluationsTruncated = writable(false);
export let userLocations = writable([]);
export let residenceLimit = writable(100);
export let updatingResidences = writable(false);
export let updatingPostcodes = writable(false);
export let updatingParishes = writable(false);
export let updatingHistoricalSales = writable(false);
export let selectedProperty = writable("EvalPrice");
export let selectedResidence = writable({});
let historyResidences = [];

let selectedPropertyValue = "EvalPrice";
let prevprevResidenceLength = 0;

const RESIDENCE_INCREMENT_FACTOR = 2;
const MAX_RESIDENCE = 68000;
const MAX_HISTORICAL = 50;
const POSTCODE = 1;
const PARISH = 2;

function updateMapDivisions(division) {
    let name; 
    let pairs;
    let avgStructure;
    if (division == POSTCODE) {
        name = "Postcode";
        pairs = emptyPostcodePairs;
        avgStructure = emptyPostcodeValues;
    }
    else {
        name = "Parish";
        pairs = emptyParishPairs;
        avgStructure = emptyParishValues;
    }

    const countAndValue = get(residencesFiltered).reduce((count, residence) => {
        let oldVal = count[residence[name]];
        count[residence[name]] = [++(oldVal[0]), oldVal[1]+residence[selectedPropertyValue]];
        return count;
    }, structuredClone(pairs));
    
    let avg = structuredClone(avgStructure);
    for (let key in avg) {
        if (avg.hasOwnProperty(key)) {
            let countAndVal = countAndValue[key];
            if (countAndVal[0] > 0) {
                avg[key] = countAndVal[1]/countAndVal[0];
            }
        }
    }
    
    if (division == POSTCODE) {
        postcodesFiltered.set(avg);
    }
    else {
        parishesFiltered.set(avg);
    }
}

function updateFilteredPostcodes(){
    updatingPostcodes.set(true);
    updateMapDivisions(POSTCODE);
    updatingPostcodes.set(false);
}

function updateFilteredParishes(){
    updatingParishes.set(true);
    updateMapDivisions(PARISH);
    updatingParishes.set(false);
}

function getElmValueFromRule(elm, rule) {
    let name = rule.name ? rule.name : rule.colName;

    if (!isNaN(elm[name])) {
        return elm[name];
    }
    if (!isNaN(rule.inputA)) { //Derived
        return eval(elm[rule.inputA]+rule.operator+elm[rule.inputB]);
    }
    if (rule.isLoc) { //Distance to point
        let coor = get(userLocations).find((loc) => loc.name == name).coor;
        return getDistance({latitude:elm["Lat"], longitude:elm["Long"]},{latitude:coor[1], longitude:coor[0]})
    }
    //Assume that it is a derived 
    let derivedPropertiesValue = get(derivedProperties);
    for (let derivedProperty of derivedPropertiesValue){
        if (derivedProperty.colName == name) {
            let val1 = getElmValueFromRule(elm, {name: derivedProperty.inputA});
            let val2 = getElmValueFromRule(elm, {name: derivedProperty.inputB});
            return eval(`(${Number(val1)})${derivedProperty.operator}(${Number(val2)})`);
        }
    }

    console.log("Did not find value");
    console.log(rule);
    console.log(elm);
    console.log(name);
    return 100;
}

function isValidByFilter(elm, filter) {
    for (const rule of filter) {
        
        let value = getElmValueFromRule(elm, rule);
        if (rule.min && value < rule.min){
            return false;
        }
        if (rule.max && value > rule.max){
            return false;
        }
        if (rule.in && !(rule.in.includes(value))){
            return false;
        }
    }
    return true;
}

function updateFilteredResidences(filter, nonSQLFilter){
    updatingResidences.set(true);

    const residenceQuery = `SELECT * FROM Residence ${filter} LIMIT ${get(residenceLimit)};`;
    db.then(db=>{
        db.query(residenceQuery).then(data => {
            data = data.map((value)=>{
                let userLocationValues = get(userLocations);
                for (let locationPoint of userLocationValues){
                    value[locationPoint.name] = getDistance({latitude:value["Lat"], longitude:value["Long"]},{latitude:locationPoint.coor[1], longitude:locationPoint.coor[0]})
                }
                let derivedPropertiesValue = get(derivedProperties);
                for (let derivedProperty of derivedPropertiesValue){
                    value[derivedProperty.colName] = eval(`(${Number(value[derivedProperty.inputA])})${derivedProperty.operator}(${Number(value[derivedProperty.inputB])})`);
                }
                return value;
            })
            data = data.filter((elm) => isValidByFilter(elm, nonSQLFilter));
            let prevResidenceLength = get(residencesFiltered).length;
            let shouldIncrementLimit = (prevResidenceLength < data.length) || (prevprevResidenceLength != prevResidenceLength && prevResidenceLength == data.length);
            console.log("Prev prev is " + prevprevResidenceLength + ". Prev amount is " + prevResidenceLength + ". new " + data.length);
            console.log(shouldIncrementLimit);
            prevprevResidenceLength = prevResidenceLength;
            residencesFiltered.set(data);
            updatingResidences.set(false);

            if (shouldIncrementLimit && get(residenceLimit)<MAX_RESIDENCE){
                residenceLimit.update((oldValue) => oldValue * RESIDENCE_INCREMENT_FACTOR);
            }
            console.log("Sample residence: ");
            console.log(data[20]);
        });
    });
}

function getHistoricalSQL() {
    return historyResidences.reduce((statement, res) => {
        statement += ("'"+res["ID"] + "', ");
        return statement;
    }, "WHERE Residence.ID in (") + "'DUMMY')"
}

function updateFilteredHistorical(){
    updatingHistoricalSales.set(true);

    const historicalQuery = `SELECT Historical.Sales as Sales, Historical.Evaluations as Evaluations, Residence.ID as ID, Residence.PropertyType as PropertyType FROM Residence inner join Historical on Historical.ID = Residence.ID ${getHistoricalSQL()};`;
    db.then(db=>{
        db.query(historicalQuery).then(data => {
            salesTruncated.set(data.length == MAX_HISTORICAL);
            data = data.filter((row)=>{return get(residencesFiltered).findIndex((residence)=>residence.ID==row.ID)!=-1});
            let unwrappedSalesData = data.map((row)=>{let obj = JSON.parse(row.Sales); obj.map((obj)=>{obj.PropertyType=row.PropertyType; return obj;}); return obj;}).filter(row=>row.length>0).reduce((accArr,newArr)=>accArr.concat(newArr),[]);
            salesFiltered.set(unwrappedSalesData);
            let unwrappedEvaluationData = data.map((row)=>{let obj = JSON.parse(row.Evaluations); obj.map((obj)=>{obj.PropertyType=row.PropertyType; return obj;}); return obj;}).filter(row=>row.length>0).reduce((accArr,newArr)=>accArr.concat(newArr),[]);
            evaluationsFiltered.set(unwrappedEvaluationData);
            updatingHistoricalSales.set(false);
        });
    });
}

function updateWithOldFilter() {
    const queryAndNonSQL = filterToQueryAndNonSQL(get(filterList));
    const filterStatement = queryAndNonSQL[0];
    const nonSQLFilter = queryAndNonSQL[1];
    updateFilteredResidences(filterStatement, nonSQLFilter);
}

derivedProperties.subscribe((val)=>{
    console.log("New derived: "+val[-1]);
    updateWithOldFilter();
})

residenceLimit.subscribe((val)=>{
    console.log("limit is " + val);
    updateWithOldFilter();
})

userLocations.subscribe(()=>{
    updateWithOldFilter();
})

filterList.subscribe((filter)=>{
    const queryAndNonSQL = filterToQueryAndNonSQL(filter);
    const filterStatement = queryAndNonSQL[0];
    const nonSQLFilter = queryAndNonSQL[1];
    updateFilteredResidences(filterStatement, nonSQLFilter);
})

selectedProperty.subscribe((prop) => {
    selectedPropertyValue = prop;
    updateFilteredPostcodes();
    updateFilteredParishes();
})

residencesFiltered.subscribe((res)=>{
    console.log("updates..");
    // if (!get(updatingResidences)) {
    //     updateWithOldFilter();
    // }
    updateFilteredPostcodes();
    updateFilteredParishes();

    if (res.slice(0,MAX_HISTORICAL) != historyResidences) {
        historyResidences = res.slice(0,MAX_HISTORICAL);
        if (historyResidences && historyResidences.length > 0) {
            updateFilteredHistorical(); 
        }
    }
})

function filterToQueryAndNonSQL(filter){
    let sqlStatement;
    let nonSQL = [];
    sqlStatement = filter.reduce((statement, filterElem) => {
        if (!(availableAttributeNames.includes(filterElem.name))) {
            nonSQL.push(filterElem);
            return statement;
        }
        if (filterElem.min){
            statement += `${filterElem.name} >= ${filterElem.min} AND `;
        }
        if (filterElem.max){
            statement += `${filterElem.name} <= ${filterElem.max} AND `;
        }
        if (filterElem.in){
            statement += `${filterElem.name} IN (${String(filterElem.in)}) AND `;
        }
        return statement;
    },"WHERE ") + "true ";
    return [sqlStatement, nonSQL];
}