export const drawXaxis = (context, xScale, Y, xExtent) => {
    const [startX, endX] = xExtent;
    let tickSize = 6,
        xTicks = xScale.ticks(), // You may choose tick counts. ex: xScale.ticks(20)
        xTickFormat = xScale.tickFormat(xTicks.length, "~s"); // you may choose the format. ex: xScale.tickFormat(tickCount, ".0s")

    context.strokeStyle = "black";

    context.beginPath();
    xTicks.forEach((d) => {
        context.moveTo(xScale(d), Y);
        context.lineTo(xScale(d), Y + tickSize);
    });
    context.stroke();

    context.beginPath();
    context.moveTo(startX, Y + tickSize);
    context.lineTo(startX, Y);
    context.lineTo(endX, Y);
    context.lineTo(endX, Y + tickSize);
    context.stroke();

    context.textAlign = "center";
    context.textBaseline = "top";
    context.fillStyle = "black";
    xTicks.forEach((d) => {
        context.beginPath();
        context.fillText(xTickFormat(d), xScale(d), Y + tickSize);
    });
};

export const drawYaxis = (context, yScale, X, yExtent) => {
    const [startY, endY] = yExtent;
    const tickPadding = 1,
        tickSize = 3,
        yTicks = yScale.ticks(),
        yTickFormat = yScale.tickFormat(yTicks.length, "~s");

    context.strokeStyle = "black";
    context.beginPath();
    yTicks.forEach((d) => {
        context.moveTo(X, yScale(d));
        context.lineTo(X - tickSize, yScale(d));
    });
    context.stroke();

    context.beginPath();
    context.moveTo(X - tickSize, startY);
    context.lineTo(X, startY);
    context.lineTo(X, endY);
    context.lineTo(X - tickSize, endY);
    context.stroke();

    context.textAlign = "right";
    context.textBaseline = "middle";
    context.fillStyle = "black";
    yTicks.forEach((d) => {
        context.beginPath();
        context.fillText(
            yTickFormat(d),
            X - tickSize - tickPadding,
            yScale(d)
        );
    });
};
