<script lang="ts">
    import * as Plot from "@observablehq/plot";
    import {evaluationsFiltered, salesFiltered} from "./filterdata.js";
    import { availablePrettyNames, propertyTypeColors } from "./structure.js";
    import {ButtonDropdown, DropdownItem, DropdownMenu, DropdownToggle} from "sveltestrap";

    // Y's
    const default_y = "PriceAdj";
    const price = "Price";
    const landValueAdj = "LandValueAdj";
    const landValue = "LandValue";
    const propertyValueAdj = "PropertyValueAdj";
    const propertyValue = "PropertyValue";
    export let y_axis_prop = default_y;

    // X's
    const default_x = "Recalculation";
    const year = "Year";
    export let x_axis_prop = default_x;

    const y_axis_attributes = [
        {x: default_x, y: default_y},
        {x: default_x, y: price},
        {x: year, y: landValueAdj},
        {x: year, y: landValue},
        {x: year, y: propertyValueAdj},
        {x: year, y: propertyValue}];

    function getPrettyName(axis_attribute) {
        let prettyName = availablePrettyNames.filter(
                    (attribute) => attribute.colName == axis_attribute,
                )[0].prettyName;
        if (prettyName != undefined) {
            return prettyName;
        } else {
            console.log("This attribute does not have a pretty name");
            return axis_attribute;
        }
    }

    let sales;
    $: sales = $salesFiltered.map((sale) => {
        return { x: new Date(sale[x_axis_prop]), y: sale[y_axis_prop], ID: sale["ID"], color:propertyTypeColors[sale["PropertyType"]]};
    }); //= [{ID:1, Recalculation:0, Price:0},{ ID:1, Recalculation:1, Price:1},]

    let evals;
    $: evals = $evaluationsFiltered.map((evaluation) => {
        return { x: new Date(evaluation[year], 0), y: evaluation[y_axis_prop], ID: evaluation["ID"], color:propertyTypeColors[evaluation["PropertyType"]]};
    }); //= [{"ID":"<some id>","Year":2011,"LandValue":404500,"LandValueAdj":478557,"PropertyValue":404500,"PropertyValueAdj":478557},{"ID":"d3d42a2f-d0ee-43ba-9a8c-852faaf2e3c8","Year":2012,"LandValue":445000,"LandValueAdj":514104,"PropertyValue":2750000,"PropertyValueAdj":3177046},{"ID":"d3d42a2f-d0ee-43ba-9a8c-852faaf2e3c8","Year":2013,"LandValue":433900,"LandValueAdj":497385,"PropertyValue":2650000,"PropertyValueAdj":3037729},{"ID":"d3d42a2f-d0ee-43ba-9a8c-852faaf2e3c8","Year":2014,"LandValue":433900,"LandValueAdj":494557,"PropertyValue":2650000,"PropertyValueAdj":3020459},{"ID":"d3d42a2f-d0ee-43ba-9a8c-852faaf2e3c8","Year":2015,"LandValue":433900,"LandValueAdj":492333,"PropertyValue":2650000,"PropertyValueAdj":3006871},{"ID":"d3d42a2f-d0ee-43ba-9a8c-852faaf2e3c8","Year":2016,"LandValue":433900,"LandValueAdj":491050,"PropertyValue":2650000,"PropertyValueAdj":2999037},{"ID":"d3d42a2f-d0ee-43ba-9a8c-852faaf2e3c8","Year":2017,"LandValue":433900,"LandValueAdj":485499,"PropertyValue":2650000,"PropertyValueAdj":2965133},{"ID":"d3d42a2f-d0ee-43ba-9a8c-852faaf2e3c8","Year":2018,"LandValue":433900,"LandValueAdj":481570,"PropertyValue":2650000,"PropertyValueAdj":2941143},{"ID":"d3d42a2f-d0ee-43ba-9a8c-852faaf2e3c8","Year":2019,"LandValue":433900,"LandValueAdj":477975,"PropertyValue":2650000,"PropertyValueAdj":2919181},{"ID":"d3d42a2f-d0ee-43ba-9a8c-852faaf2e3c8","Year":2021,"LandValue":433900,"LandValueAdj":467309,"PropertyValue":2650000,"PropertyValueAdj":2854043},{"ID":"d3d42a2f-d0ee-43ba-9a8c-852faaf2e3c8","Year":2022,"LandValue":433900,"LandValueAdj":433900,"PropertyValue":2650000,"PropertyValueAdj":2650000}]
    $: {getPrettyName
        if (y_axis_prop == default_y || y_axis_prop == price) {
            linePlot(sales, false);
        } else {
            linePlot(evals, true);
        }
    }
    let div;
    function linePlot(sales, isEvaluation) {
        div?.firstChild?.remove(); // remove old chart, if any
        let plotGraph = Plot.plot({
            y: {
                type: "linear",
                tickFormat: "s",
                grid: true,
                nice: true,
                label: getPrettyName(y_axis_prop),
            },
            x: {
                label: getPrettyName(x_axis_prop),
            },
            marks: [
                Plot.ruleY([0]),
                Plot.lineY(sales, { x: "x", y: "y", z: "ID", stroke:"color", curve: (isEvaluation?"step-after":"linear"), strokeDasharray:(isEvaluation?"":"12 4")}),
                Plot.dotY(sales,{x:"x",y:"y", fill:"color"})
            ],
        });
        plotGraph.style.setProperty("width","100%");
        div?.append(plotGraph);
    }
</script>
<main style="position: relative">
    <div bind:this={div} id="lineplot"></div>
    <ButtonDropdown size="sm" style="position: absolute; top: 0.5rem; right: 0.5rem;">
        <DropdownToggle caret>✏️</DropdownToggle>
        <DropdownMenu>
            {#each y_axis_attributes as attribute}
                <DropdownItem active={y_axis_prop === attribute.y} on:click={()=>{y_axis_prop = attribute.y; x_axis_prop = attribute.x}}>{getPrettyName(attribute.y)}</DropdownItem>
            {/each}
        </DropdownMenu>
    </ButtonDropdown>
</main>
